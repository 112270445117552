import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { User } from '../models/user';
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiUrl = `${environment.apiUrl}/users`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) {
  }
  
  getAll(): Observable<User[]> {
    const url = `${this.apiUrl}?filter={"include":[{"relation":"branch"}]}`;

    return this.http.get<User[]>(url).pipe(
      catchError(this.handleError)
    );
  }

  getData(id: string): Observable<User> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.get<User>(url).pipe(
      catchError(this.handleError)
    );
  }

  myData(): Observable<User> {
    const url = `${this.apiUrl}/me?filter[include][0][relation]=branch`;
    return this.http.get<User>(url).pipe(
      catchError(this.handleError)
    );
  }

  addData(user: User): Observable<User> {
    return this.http.post<User>(this.apiUrl, user, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  updateData(user: User): Observable<User> {
    const id = typeof user === 'string' ? user : user.id;
    const url = `${this.apiUrl}/${id}`;

    return this.http.put<User>(url, user, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  resetPassword(data): any {
    const url = `${this.apiUrl}/password-reset`;
    delete data.retypeNewPassword;
    
    return this.http.put<{token:  string}>(url, data, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  deleteData(user: User): Observable<User> {
    const id = typeof user === 'string' ? user : user.id;
    const url = `${this.apiUrl}/${id}`;

    return this.http.delete<User>(url, this.httpOptions).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `Error: ${error.error.message}`;
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.message}`);
    }
    swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: error.error.error.message,
      showCloseButton: true,
      customClass: {
        confirmButton: 'btn btn-primary'
      },
      buttonsStyling: false,
    });
    
    // Return an observable with a user-facing error message.
    return throwError(errorMessage);
  }

}
