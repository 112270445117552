import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { JwtHelperService } from "@auth0/angular-jwt";

const helper = new JwtHelperService();

@Injectable()
export class AuthService {
  private apiUrl = `${environment.apiUrl}/users`;

  constructor(private httpClient: HttpClient, public router: Router) {

  }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    return this.httpClient.post<{accessToken: string, refreshToken: string}>(`${this.apiUrl}/refresh-login`, {email, password}).pipe(
      tap(res => {
      localStorage.setItem('access_token', res.accessToken);
      localStorage.setItem('refresh_token', res.refreshToken);
      this.startRefreshTokenTimer();
    }));
  }

  refreshToken() {
    const refreshToken = localStorage.getItem('refresh_token');
    return this.httpClient.post<{accessToken: string}>(`${this.apiUrl}/refresh`, {refreshToken}).pipe(
      tap(res => {
        localStorage.setItem('access_token', res.accessToken);
        this.startRefreshTokenTimer();
    }));
  }

  isAuthenticated() {
    return localStorage.getItem('access_token') !==  null;
  }

  isTokenExpired() {
    const token = localStorage.getItem('access_token');

    return helper.isTokenExpired(token);
  }

  getUser() {
    const token = localStorage.getItem('access_token');

    return helper.decodeToken(token);
  }

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const token = localStorage.getItem('access_token');
    
    // set a timeout to refresh the token a minute before it expires
    const expires = helper.getTokenExpirationDate(token);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  public stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}  
